import { Box, styled } from '@mui/material';
import { PhoneComponentProps } from './types';

type PhoneFrameStyleProps = Pick<PhoneComponentProps, "size" | "elevation">;

export const StyledPhoneFrame = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'elevation',
})<PhoneFrameStyleProps>(({ theme, size = 300, elevation = true }) => ({
    display: "block",
    backgroundColor: theme.palette.background.paper,
    backgroundSize: "auto 200%, cover",
    backgroundRepeat: "no-repeat, no-repeat",
    border: `solid ${theme.palette.background.paper}`,
    marginBottom: "2em",
    position: "relative",
    overflow: "hidden",
    boxShadow: elevation ? `0 0.5em 2em 0.2em rgba(0, 0, 0, 0.13), 0 0 0 0.5px ${theme.palette.background.default} inset` : "0",
    transition: "all .1s linear, line-height 0s linear",
    transformOrigin: "bottom center",
    backgroundPosition: "50% -100%, center center",
    "&:hover": {
        boxShadow: elevation ? `0 0.5em 2em 0.2em rgba(0, 0, 0, 0.16), 0 0 0 0.5px ${theme.palette.background.default} inset` : "0"
    },
    width: `${size}px`,
    height: `calc(1.98*${size}px)`,
    lineHeight: `calc(2.165*${size}px)`,
    borderWidth: `calc(${size}px/15.625)`,
    borderRadius: `calc(${size}px/5.86)`,
    zIndex: 200
}));

export const StyledPhoneScreenshot = styled('img')`
    height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
