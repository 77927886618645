import { Box, Typography, styled, alpha, hexToRgb } from "@mui/material";

export const OrbitRing = styled(Box, { shouldForwardProp: prop => prop !== 'size' && prop !== 'opacity' })<{ size?: number, opacity?: number }>(({ theme, size = 334, opacity = 0.5 }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: `${size}px`,
    height: `${size}px`,
    border: `2px solid ${theme.palette.mode === "dark" ? `rgba(1, 155, 221, ${opacity + 0.3})` : `rgba(84, 132, 178, ${opacity + 0.1})`}`,
    boxSizing: 'border-box',
    borderRadius: size,
    zIndex: 0,
}));

// @deprecated since image was replaced with video.
export const ThumbnailImage = styled('img')(({ theme }) => ({
    height: "756px",
    maxHeight: "100%",
    zIndex: 1,
    width: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    userSelect: 'none'
}));

export const ThumbnailVideo = styled('video')(({ theme }) => ({
    height: "756px",
    maxHeight: "100%",
    zIndex: 1,
    width: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    userSelect: 'none'
}));

export const StyledTitle = styled(Typography)(() => ({ fontSize: "3rem", marginBottom: "20px", lineHeight: "3.2rem", letterSpacing: "-0.02em", fontStyle: "normal", fontWeight: "bold" }));
export const StyledSlogan = styled(Typography)(() => ({ fontSize: "18px", whiteSpace: "pre-wrap", opacity: 0.7 }));

export const StyledMobileTitleBox = styled(Box)(({ theme }) => ({
    backgroundColor: alpha(hexToRgb(theme.palette.background.default), 0.7),
}))