import { Autocomplete, AutocompleteProps } from "@mui/material";
import { Controller } from "react-hook-form";

type FormAutocompleteProps = AutocompleteProps<any, any, any, any> & {
  name: string;
  control: any;
};

export function FormAutocomplete({
  name,
  control,
  ...props
}: FormAutocompleteProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...props}
          onChange={(event, option, reason) => {
            field.onChange(option.value);
            if (props.onChange) {
              props.onChange(event, option, reason);
            }
          }}
        />
      )}
    />
  );
}
