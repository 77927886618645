import { styled, Box, Link } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

export const StyledFooter = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: "24px 0",
    marginTop: "24px"
}));

export const StyledLink = styled(Link)(() => ({
    opacity: 0.8,
    marginLeft: 10,
    marginRight: 10,
    transition: "opacity 255ms ease",
    cursor: "pointer",

    "&:hover": {
        opacity: 0.7,
    }
}));

export const StyledRouterLink = styled(RouterLink)(({theme}) => ({
    opacity: 0.8,
    marginLeft: 10,
    marginRight: 10,
    transition: "opacity 255ms ease",
    cursor: "pointer",
    color: theme.palette.text.primary,
    textDecoration: "none",

    "&:hover": {
        opacity: 0.7,
    }
}));