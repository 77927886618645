import HomePage from "./pages/Home";
import { getInitialLocale, LitteraService } from "./utils/translations";
import { ThemeService } from "./utils/theme";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import BannersDialog from "./components/BannersDialog";
import { AccountDeletePage } from "pages/AccountDelete";
import Footer from "components/Footer";
import TitleBar from "components/TitleBar";
import { PrivacyPolicyPage } from "pages/PrivacyPolicy";

function App() {
  return (
    <ThemeService>
      <LitteraService initialLocale={getInitialLocale()}>
        <BrowserRouter>
          <TitleBar />
          <Routes>
            <Route path="/" element={<HomePage />}>
              <Route path="banners" element={<BannersDialog open />} />
            </Route>
            <Route path="account-delete" element={<AccountDeletePage />} />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </LitteraService>
    </ThemeService>
  );
}

export default App;
