import React from "react";
import { Box, Stepper, Step, StepLabel, StepIconProps, Grow } from "@mui/material";
import { Link, } from "react-scroll";
import useScrollSpy from "react-use-scrollspy";

import { BackgroundCircle, StyledStepIcon, StyledPhoneComponentFixed } from "./styles";
import { TextContainer } from "./TextContainer";

/**
 * A component displaying a phone sliding across multiple sections with content.
 */
const PhoneSlideSection = ({ data, id }: { data: { title: string, description: string, screenshot: string }[], id?: string }) => {

    const sectionRefs = [
        React.useRef(null as unknown as HTMLDivElement),
        React.useRef(null as unknown as HTMLDivElement),
        React.useRef(null as unknown as HTMLDivElement),
    ]
    const activeSection = useScrollSpy({ sectionElementRefs: sectionRefs, offsetPx: -(window.innerHeight / 1.6), });

    const stepper = <Stepper activeStep={activeSection}>
        {
            data.map((item, index) => {
                return <Step completed={false} key={index}>
                    <Link
                        to={`phone-slide-section-${index}`}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        style={{ cursor: "pointer" }}>
                        <StepLabel StepIconComponent={StepIcon} style={{ cursor: "pointer" }}>
                            {item.title}
                        </StepLabel>
                    </Link>
                </Step>
            })
        }
    </Stepper>

    return <Box position="relative" marginTop="-100px" display="flex" id={id}>
        <Box height="300vh" width="60%" position="relative" display="flex" justifyContent="center">
            <StyledPhoneComponentFixed
                elevation={activeSection > -1}
                size={255}
                screenshot={data[activeSection].screenshot} />

            <Grow in={activeSection === 0}>
                <BackgroundCircle marginTop="calc(50vh - 150px)" />
            </Grow>
            <Grow in={activeSection === 1}>
                <BackgroundCircle marginTop="calc(150vh - 150px)" />
            </Grow>
            <Grow in={activeSection === 2}>
                <BackgroundCircle marginTop="calc(250vh - 150px)" />
            </Grow>
        </Box>

        <Box paddingLeft="30px" maxWidth={700}>
            {
                data.map((item, index) =>
                (<TextContainer
                    order={index}
                    key={item.title}
                    title={item.title}
                    StepperElement={stepper}
                    description={item.description}
                    sectionRef={sectionRefs[index]}
                    active={activeSection === index} />))
            }
        </Box>
    </Box>
}

const StepIcon = (props: StepIconProps) => (
    <StyledStepIcon
        active={props.active ?? false} />);

export default PhoneSlideSection