import { makeTranslations } from "../../utils/translations";

export const useTrans = makeTranslations({
    de_DE: {
        threePhonesSection: [
            `Verwalte dein\nProfil`,
            `Wähle ein oder\nmehrere Bibliotheken aus`,
            `Leserkarte immer\ndabei`,
        ],
        itsWhatYouKnow: `Was du schon gut kennst.\nJetzt auf Ihrem mobilen Gerät.`,
        jumpOnBoard: "Also, worauf wartest Du?\nSpringe an Bord!",
        phoneSlideSectionTitle: [
            "Alles an\neinem Ort",
            "Suchen Sie durch die Sammlung\nder Bibliothek",
            "Schluss mit dem\nVergessen",
        ],
        phoneSlideSectionDescription: [
            `Verschwenden Sie keine Zeit mit der Suche nach Ihrer Reservierung und ausgeliehenen Büchern.\nDie wichtigsten Informationen finden Sie auf Ihrem personalisierten Startbildschirm.\nEs warten Bücher, die Sie dazu bringen, sie in einem Atemzug zu lesen oder sogar die ganze Welt zu vergessen.`,
            `Bibliotheksbestände werden systematisch erweitert und ständig neue Titel hinzugefügt. Durchsuchen Sie Tausende von Beschreibungen, um etwas Interessantes für Sie oder Ihre Freunde zu finden.\nDie Suchmaschine und die Filter in der Anwendung helfen Ihnen bei der schnellen Auswahl.`,
            `Die Anwendung erinnert Sie an die ausgeliehenen Bücher, die Sie demnächst in die Bibliothek zurückgeben müssen. Es bietet Ihnen auch die Möglichkeit, den Rückgabetermin zu verschieben, wenn Sie noch nicht fertig gelesen haben.`
        ],
    },
    pl_PL: {
        threePhonesSection: [
            `Zarządzaj swoim\nprofilem`,
            `Wybierz jedną lub\nwięcej bibliotek`,
            `Miej pod ręką\nkartę czytelnika `,
        ],
        itsWhatYouKnow: `To, co już dobrze znasz.\nNa Twoim urządzeniu mobilnym.`,
        jumpOnBoard: "Na co więc czekasz?\n Wskakuj na pokład!",
        phoneSlideSectionTitle: [
            "Wszystko w\njednym miejscu",
            "Przeglądaj zbiory\nbiblioteki",
            "Koniec z\nzapominaniem"
        ],
        phoneSlideSectionDescription: [
            `Nie trać czasu na szukanie rezerwacji i wypożyczeń.\nInformacje o zbiorach znajdziesz na ekranie smartfona.\nCzekają tu też na Ciebie książki, które przeczytasz jednym tchem lub przy których zapomnisz o całym świecie.`,
            `Zbiory bibliotek są systematycznie powiększane i wciąż przybywa nowych tytułów. Wyszukuj między nimi i znajdź coś interesującego dla siebie lub przyjaciół.\nWyszukiwarka i filtry w aplikacji pomogą Ci dokonać szybkiego wyboru.`,
            `Aplikacja przypomni Ci o wypożyczonych zbiorach, które będziesz musiał niebawem zwrócić do biblioteki.\nZaproponuje Ci też możliwość przesunięcia daty zwrotu, gdybyś jeszcze nie skończył czytać.`
        ],
    },
    en_US: {
        threePhonesSection: [
            `Manage your\nprofile`,
            `Choose one or\nmore libraries`,
            `Have your\nreader card handy`,
        ],
        itsWhatYouKnow: `What you already know well.\nOn your mobile device.`,
        jumpOnBoard: "So what are you waiting for?\nJump on board!",
        phoneSlideSectionTitle: [
            "Everything in\none place",
            "Look through the collection\nof the library",
            "Quit the\nforgetting",
        ],
        phoneSlideSectionDescription: [
            `Do not waste time looking for a reservation or loan.\nYou will find the most important information on your personalized home screen.\nThere are books waiting that will you make you read them in one breath or even forget about the whole world.`,
            `Library collections are systematically expanded and new titles are constantly being added. Search between thousands of descriptions to find something interesting for you or your friends.\nThe search engine and filters in the application will help you make a quick choice.`,
            `The application will remind you about the borrowed collections that you will have to return to the library soon.\nIt will also offer you the option to postpone the return date if you haven't finished reading yet.`,
        ],
    }
})