import { makeTranslations } from "../../../../utils/translations";

export const useTrans = makeTranslations({
  en_US: {
    title: "Pick books\nstraight from your\nsmartphone",
    description:
      "Thousands of books, comics, audiobooks\nare waiting in the library...",
    download: "Go to download",
    explore: "Explore",
    freeDownload: "Free download",
  },
  de_DE: {
    title: "Wählen Sie Bücher\ndirekt von Ihrem\nSmartphone aus",
    description:
      "Tausende Bücher, Comics, Hörbücher\nwarten in der Bibliothek...",
    download: "Zum Download gehen",
    explore: "Entdecken",
    freeDownload: "Kostenloser Download",
  },
  pl_PL: {
    title: "Wybieraj książki\nprosto ze swojego\nsmartfona",
    description:
      "W bibliotece czekają tysiące\nksiążek, komiksów, audiobooków...",
    download: "Przejdź do pobrania",
    explore: "Eksploruj",
    freeDownload: "Wolna od opłat",
  },
});
