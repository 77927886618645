import { useState, useEffect } from "react";

export function useLibraries() {
  const [loading, setLoading] = useState(false);
  const [libOptions, setLibOptions] = useState([]);

  useEffect(() => {
    async function getLibraries() {
      setLoading(true);
      const libraries = await fetchLibraries();
      if (libraries) {
        setLibOptions(
          libraries.map((lib: any) => ({
            value: lib.domain,
            label: lib.title,
            ...lib,
          }))
        );
        setLoading(false);
      }
    }
    getLibraries();
  }, []);

  return { libOptions, loading };
}

async function fetchLibraries() {
  const headers = new Headers({
    Accept: "application/json",
  });

  let response: Response;

  try {
    response = await fetch("https://sowamobi.sokrates.pl/config/config.json", {
      method: "POST",
      headers,
    });
  } catch (err) {
    throw err;
  }

  const result = await response.json();
  return result;
}
