import { Box, BoxProps, Container, ContainerProps as _ContainerProps, Stack } from "@mui/material";
import React from "react";

export type SplitSectionProps = {
    children: [React.ReactNode, React.ReactNode];
    style?: React.CSSProperties;
    LeftBoxProps?: BoxProps;
    RightBoxProps?: BoxProps;
    ContainerProps?: _ContainerProps;
    sectionRef?: React.Ref<HTMLDivElement>;
}

const SplitSection = ({ children, style, LeftBoxProps, RightBoxProps, ContainerProps, sectionRef: ref }: SplitSectionProps) => {

    if (!Array.isArray(children) || children.length !== 2) {
        throw new Error("SplitSection expects an array of two children");
    }

    return <Box minHeight="100vh" overflow="hidden" style={style} ref={ref}>
        <Container maxWidth="xl" style={{ height: "100%" }} {...ContainerProps}>
            <Stack direction={{ xs: "column", sm: "column", md: "row" }} justifyContent="space-evenly" alignItems="center" height="100%">
                <Box flex={1} {...LeftBoxProps}>
                    {children[0]}
                </Box>
                <Box flex={1} {...RightBoxProps}>
                    {children[1]}
                </Box>
            </Stack>
        </Container>
    </Box>
}

export default SplitSection;