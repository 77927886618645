/* External packages imports */
import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import { Box } from "@mui/system";

/* Project scope imports. */
import sowaMobiLogoLight from "../../assets/webp/sowa-mobi-logo.webp";
import sowaMobiLogoDark from "../../assets/webp/sowa-mobi-logo-dark.webp";
import { useThemeMode } from "../../utils/theme";

/* Component scope imports. */
import { ThemeModeSwitch } from "./ThemeModeSwitch";
import { Link } from "react-router-dom";

const TitleBar = () => {
  const { mode, toggle } = useThemeMode();

  const sowaMobiLogo = mode === "dark" ? sowaMobiLogoDark : sowaMobiLogoLight;

  return (
    <AppBar position="absolute" color="transparent" elevation={0} id="appbar">
      <Toolbar>
        <Box height="34px" flexGrow={1}>
          <Link to="/">
            <img
              src={sowaMobiLogo}
              alt="Sowa Mobi logo"
              style={{ height: "34px", width: "auto" }}
            />
          </Link>
        </Box>
        <ThemeModeSwitch checked={mode === "dark"} onChange={toggle} />
      </Toolbar>
    </AppBar>
  );
};

export default TitleBar;
