// Dependencies scoped imports
import React, { useLayoutEffect } from "react";
import ArrowTopIcon from "@mui/icons-material/ExpandLess";
import useScrollSpy from "react-use-scrollspy";
import { Grow, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-scroll";

// Project scoped imports

// Module scoped imports
import { StyledRoot } from "./styles";
import { BackUpButtonProps } from "./types";

/**
 * @description The button allowing user to scroll back to top.
 *
 * API:
 *
 * - inherits [Box API](https://material-ui.com/api/box/)
 */
const BackUpButton = ({ sx, classes }: BackUpButtonProps) => {
  const topRef = React.useRef(null as unknown as HTMLDivElement);
  const firstSectionRef = React.useRef(null as unknown as HTMLDivElement);
  const bottomRef = React.useRef(null as unknown as HTMLDivElement);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const activeSection = useScrollSpy({
    sectionElementRefs: [topRef, firstSectionRef, bottomRef],
  });

  useLayoutEffect(() => {
    topRef.current = document.querySelector(
      "#root"
    )! as unknown as HTMLDivElement;
    firstSectionRef.current = document.querySelector(
      "#first_section"
    )! as unknown as HTMLDivElement;
    bottomRef.current = document.querySelector(
      "#laptopsection"
    )! as unknown as HTMLDivElement;
  }, []);

  if(isMobile) return null;

  return (
    <Link to="appbar" smooth={true} duration={500}>
      <Grow in={activeSection > 0}>
        <StyledRoot
          sx={sx}
          bottomGutter={activeSection > 1}
          className={classes?.root}
        >
          <ArrowTopIcon />
        </StyledRoot>
      </Grow>
    </Link>
  );
};

export default BackUpButton;
