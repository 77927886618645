import { TextField, TextFieldProps, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";

type FormTextFieldProps = TextFieldProps & {
  name: string;
  control: any;
  rules?: any;
};

export function FormTextField({
  name,
  control,
  rules,
  ...props
}: FormTextFieldProps) {
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className="text-field">
          <TextField
            {...props}
            onChange={field.onChange}
            error={Boolean(fieldState.error?.message)}
          />
          {fieldState.error?.message && (
            <p
              className="text-field__error"
              style={{ color: theme.palette.error.main }}
            >
              {fieldState.error?.message}
            </p>
          )}
        </div>
      )}
    />
  );
}
