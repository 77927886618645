import { styled, Paper, Box, hexToRgb, alpha } from "@mui/material";
import { BOOKCOVER_HEIGHT, BOOKCOVER_WIDTH } from "./variables";

export const BookCover = styled("img")`
  width: auto;
  height: 100%;
`;

export const BookCoverPaper = styled(Paper)`
  position: relative;
  overflow: hidden;
  width: ${BOOKCOVER_WIDTH}px;
  height: ${BOOKCOVER_HEIGHT}px;
`;

export const CarouselContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: `40px 0 40px`,
  position: "relative",
}));

export const StyledBackdrop = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(hexToRgb(theme.palette.background.default), 0.7),
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  maxWidth: "100%",
  height: "100%",
  zIndex: 100,
}));
