// Dependencies scoped imports
import React from 'react';
import { SOWA_BANNER_FRAME_SIZE } from './constants';

// Project scoped imports

// Module scoped imports
import { StyledCard, StyledImage, StyledPre, StyledRoot } from './styles';
import { BannerFrameProps } from './types';

/**
 * @description A frame displaying an embedable banner.
 *
 * API:
 *
 * - inherits [Box API](https://material-ui.com/api/box/)
 */
const BannerFrame = ({ sx, classes, size, outlined, src, href }: BannerFrameProps) => {
  // const defaultSrc = `https://www.strix.sowa.pl/static/img/banners/opac-${width}x${height}${outlined ? "-f" : ""}.jpg`
  // const defaultSrc = `${window.location.origin}/assets/media/sowa_mobi_media.png`;
  const baseUrl = window.location.origin;
  const imageName = `${size}${outlined ? "_f" : ""}_media`;
  const defaultSrc = `${baseUrl}/assets/media/${imageName}.png`;

  const width = SOWA_BANNER_FRAME_SIZE[size][0];
  // const height = frameSize[size][1];

  return (
    <StyledRoot alignItems="center" sx={sx} className={classes?.root} style={{ width: "100%", maxWidth: width + "px" }}>
      <StyledImage src={src ?? defaultSrc} alt="banner" style={{ width: "100%", maxWidth: width + "px" }} />

      <StyledCard variant="outlined">
        <StyledPre>
          <code>
            {createEmbedCode(href, src ?? defaultSrc)}
          </code>
        </StyledPre>
      </StyledCard>
    </StyledRoot>
  );
};

const createEmbedCode = (href: string, src: string) => 
`<a href="${href}" target="_blank">
  <img src="${src}" />
</a>`;

export default BannerFrame;
