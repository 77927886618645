import { Box, styled } from "@mui/material";
import { PhoneComponent } from "../../../../components/PhoneComponent";

export const BackgroundCircle = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.primary.main,
  width: 350,
  height: 350,
  borderRadius: 350,
  marginBottom: "auto",
  marginRight: "auto",
}));

export const StyledStepIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme, active }) => ({
  width: "8px",
  height: "8px",
  borderRadius: "8px",
  background: active ? theme.palette.primary.main : theme.palette.text.disabled,
}));

export const StyledPhoneComponentFixed = styled(PhoneComponent)(() => ({
  position: "sticky",
  top: window.innerHeight / 2 - (2.15 * 255) / 2,
  left: "10%",
  zIndex: 4,
}));
