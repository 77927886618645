import React from 'react';
import { Alert, Button, Container, Typography } from '@mui/material';
import { makeTranslations } from './translations';

class ErrorBoundary extends React.Component<any, {error: any, errorInfo: any}> {
    constructor(props: {}) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
          <ErrorBoundaryAlert error={this.state.error} errorInfo={this.state.errorInfo} />
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

const ErrorBoundaryAlert = ({error, errorInfo}: {error: any, errorInfo: any}) => {
    const translated = useTrans();

    return (
        <Container maxWidth="xl">
            <Alert variant='outlined' severity="error" action={<Button color="inherit" size="small">{translated.retry}</Button>}>
                <Typography>{translated.title}</Typography>

                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {error && error.toString()}
                    <br />
                    {errorInfo.componentStack}
                </details>
            </Alert>
        </Container>
        )
}

const useTrans = makeTranslations({
    en_US: {
        title: "Something went wrong",
        retry: "Try again"
    },
    de_DE: {
        title: "Etwas ist schief gelaufen",
        retry: "Nochmal versuchen"
    },
    pl_PL: {
        title: "Coś poszło nie tak",
        retry: "Spróbuj ponownie"
    }
})

export default ErrorBoundary;