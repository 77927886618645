// Dependencies scoped imports
import React from 'react';
import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Project scoped imports
import BannerFrame from '../BannerFrame';
import { BannerFrameSizes } from '../BannerFrame/types';

// Module scoped imports
import { useTrans } from './trans';
import { StyledRoot, StyledSloganTypography } from './styles';
import { BannersDialogProps } from './types';
import { SOWA_BANNER_FRAME_SIZE } from '../BannerFrame/constants';

/**
 * @description A dialog displaying embedable banners.
 *
 * API:
 *
 * - inherits [Dialog API](https://material-ui.com/api/dialog/)
 */
const BannersDialog = ({ sx, classes }: BannersDialogProps) => {
  let navigate = useNavigate();
  const translated = useTrans();

  const handleClose = () => {
    navigate('/');
  }

  return (
    <>
      <StyledRoot 
        maxWidth="xl" 
        fullWidth 
        open 
        onClose={handleClose} 
        sx={sx} 
        className={classes?.root}>
        <DialogTitle>
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
            {translated.title}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
            <StyledSloganTypography>{translated.description}</StyledSloganTypography>
            <br />
            <br />
            <br />

            <Stack spacing={3} divider={<Divider />}>
              <BannerFrameTandem size="xs" />
              <BannerFrameTandem size="sm" />
              <BannerFrameTandem size="md" />
              <BannerFrameTandem size="lg" />
            </Stack>

        </DialogContent>
      </StyledRoot>
    </>
  );
};

const BannerFrameTandem = ({ size }: { size: BannerFrameSizes }) => {
  const translated = useTrans();

  const width = SOWA_BANNER_FRAME_SIZE[size][0];
  const height = SOWA_BANNER_FRAME_SIZE[size][1];

  return <Box marginY={3}>
  <Typography variant="h5" align="center" style={{ marginBottom: "12px" }}>{translated.resolution} {width}x{height}</Typography>

  <Stack flexDirection="row" flexWrap="wrap" alignItems="center" justifyContent="center">
    <BannerFrame
      size={size}
      href="https://mobi.sowa.pl/" />
    <BannerFrame
      size={size}
      outlined
      href="https://mobi.sowa.pl/" />
  </Stack>
</Box>
}

export default BannersDialog;
